<template>
  <div
    :class="'member-page member-page-'+currVip.id + (!Object.keys(userInfo).length || userVip.vipRightId==='3'?' curr-user-not-vip':'' )">
    <v-header :use-holder="true"/>


    <!--    用户会员权益卡片-->
    <div class="vip-info-card">
      <div class="go-record-btn" @click="$router.push('/record?tab=CONSUME&type=2')">
        开通记录
        <van-icon name="arrow"/>
      </div>
      <div class="avatar">
        <img class="avatar-img"
             :src="userInfo.avatar || 'https://cdn.001ppt.cn/pc2/static/imgs/jpg/defaultAvatar.jpg'"
             alt="">
      </div>
      <div class="info">
        <div class="nickName" v-if="Object.keys(userInfo).length">{{ userInfo.username }}</div>
        <div class="nickName" v-else>游客状态</div>
        <div class="vip-expire-info">
          <template v-if="!Object.keys(userInfo).length">未登录</template>
          <template v-else-if="userVip.isInfinityVip">{{ userVip.vipRightName }} · 有效期已买断</template>
          <template v-else-if="userVip.vipRightId === '3' && userVip.lastExpireVipRightId">
            <template v-if="userVip.lastExpireVipRightId === '4'">超级VIP会员·已过期{{ userVip.lastExpireVipDays }}天
            </template>
            <template v-else-if="userVip.lastExpireVipRightId === '5'">
              俱乐部会员·已过期{{ userVip.lastExpireVipDays }}天
            </template>
            <template v-else-if="userVip.lastExpireVipRightId === '1'">
              试用会员·已过期{{ userVip.lastExpireVipDays }}天
            </template>
          </template>
          <template v-else-if="userVip.vipRightId === '3'">未开通</template>
          <template v-else>
            {{ userVip.vipRightName }}有效期至: {{ userVip.expireTime.slice(0, 10) }}
          </template>
        </div>
      </div>
    </div>

    <div class="vip-info-scroll-card" :style="{
      visibility: styles.fixed?'':'hidden',
      zIndex: styles.fixed?'1':'-100'
    }">
      <div class="go-record-btn" @click="$router.push('/record?tab=CONSUME&type=2')">
        开通记录
        <van-icon name="arrow"/>
      </div>
      <div class="avatar">
        <img class="avatar-img"
             :src="userInfo.avatar || 'https://cdn.001ppt.cn/pc2/static/imgs/jpg/defaultAvatar.jpg'"
             alt="">
      </div>
      <div class="info">
        <div class="nickName" v-if="Object.keys(userInfo).length">{{ userInfo.username }}</div>
        <div class="nickName" v-else>游客状态</div>
        <div class="vip-expire-info">
          <template v-if="!Object.keys(userInfo).length">未登录</template>
          <template v-else-if="userVip.isInfinityVip">{{ userVip.vipRightName }} · 有效期已买断</template>
          <template v-else-if="userVip.vipRightId === '3' && userVip.lastExpireVipRightId">
            <template v-if="userVip.lastExpireVipRightId === '4'">超级VIP会员·已过期{{ userVip.lastExpireVipDays }}天
            </template>
            <template v-else-if="userVip.lastExpireVipRightId === '5'">
              俱乐部会员·已过期{{ userVip.lastExpireVipDays }}天
            </template>
            <template v-else-if="userVip.lastExpireVipRightId === '1'">
              试用会员·已过期{{ userVip.lastExpireVipDays }}天
            </template>
          </template>
          <template v-else-if="userVip.vipRightId === '3'">未开通</template>
          <template v-else>
            {{ userVip.vipRightName }}有效期至: {{ userVip.expireTime.slice(0, 10) }}
          </template>
        </div>
      </div>
    </div>


    <div class="plan-detail-vip-popup">
      <!--    购买记录-->
      <div class="vip-buy-record">
        <van-swipe vertical style="height: 24px" :autoplay="1500" :show-indicator="false" indicator-color="#fff">
          <van-swipe-item v-for="item in vipBuyRecords" :key="item.id">
            <div class="vip-buy-record-item">
              {{ item.userMobile }} 刚刚购买了
              <div class="vip-buy-record-item-vip-right-name">{{ item.vipRightName }}</div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>


      <!--    tab栏-->
      <van-sticky :offset-top="styles.tabOffsetTop" @change="styles.fixed=$event">
        <div :class="'tab tab-'+currVip.id">
          <div class="tab-item" :class="{active:currVip.id === '4'}" @click="switchVipRight('4')">
            <div style="right: -10px" v-if="currVip.id === '4'&&currVip.chooseRate>0" class="choose-rate">
              {{ currVip.chooseRate }}%用户选择
            </div>
            <div style="visibility: hidden">超级VIP会员</div>
          </div>
          <div class="tab-item" :class="{active:currVip.id === '5'}" @click="switchVipRight('5')">
            <div style="right: 4px" v-if="currVip.id === '5'&&currVip.chooseRate>0" class="choose-rate">
              {{ currVip.chooseRate }}%用户选择
            </div>
            <div style="visibility: hidden">俱乐部会员</div>
          </div>
          <div class="tab-item" :class="{active:currVip.id === '6'}" @click="switchVipRight('6')">
            <div v-if="currVip.id === '6'&&currVip.chooseRate>0" class="choose-rate">{{
                currVip.chooseRate
              }}%用户选择
            </div>
            <div style="visibility: hidden">AI会员</div>
          </div>
        </div>
      </van-sticky>


      <div :class="'vip vip-'+currVip.id">
        <!--    套餐-->
        <div class="package" v-if="currVip.packages && currVip.packages.length">
          <div class="package-item"
               @click.stop="switchPackage(item)"
               :class="{active:currPackage && currPackage.id===item.id}"
               v-for="item in currVip.packages" :key="item.id"
               :style="`width: calc((100vw - 48px) / ${currVip.packages.length})`"
          >
            <div class="package-item-origin-price" v-if="currPackage.id === item.id && item.ticketDiscountMoney">
              ¥{{ item.ticketDiscountMoney + item.price }}
            </div>
            <div class="package-item-title">{{ item.title }}</div>
            <div class="package-item-price">
              <template v-if="item.id === currPackage.id">¥{{ moneyFilter(computedPrice.finalPrice) }}</template>
              <template v-else>¥{{ moneyFilter(item.price) }}</template>
            </div>
            <div class="package-item-slogan">{{ item.slogan }}</div>
            <div class="package-item-validity">
              <template v-if="item.validityDays > 90000">从此会员自由</template>
              <template v-else-if="item.displayValidityUnit==='小时'">付费起{{
                  item.validityDays * 24
                }}小时
              </template>
              <template v-else>付费起{{ item.validityDays }}天</template>
            </div>
          </div>
        </div>


        <!--    会员方案价值-->
        <div class="vip-plan-value" style="margin-bottom: 6px">
          开通后价值 ￥{{ (sumPrice.memberFree || 0).toFixed(0) }}
          <template v-if="currVip.memberFreePlanOnlineOnly">方案免费解锁 (不支持下载)</template>
          <template v-else>方案免费下载</template>
        </div>

        <!--        抵扣券-->
        <div style="display: flex;align-items: center;justify-content: center;"
             v-if="currPackage.ticketDiscountMoney">
          <div class="curr-package-slogan">[直减券] {{ currPackage.ticketTitle || currPackage.slogan }}</div>
          <div class="curr-package-discount">
            已抵扣¥{{ currPackage.ticketDiscountMoney }}
          </div>
        </div>

        <!--        补差价-->
        <div style="display: flex;align-items: center;justify-content: center;margin: 4px 0"
             v-if="remainDiscountMoney && (currVip.isLevelUp || (currPackage.isSupportSameVipRightLevelUp && currVip.id === userVip.vipRightId))">
          <div class="curr-package-slogan">[补差价] 按实付均摊有效期</div>
          <div class="curr-package-discount">
            已抵扣¥{{ moneyFilter(remainDiscountMoney) }}
          </div>
        </div>


        <!--        权益说明-->
        <div class="rights">
          <div class="rights-card">
            <div :class="'rights-item '+(item.isGray?'disabled':'')" v-for="(item,index) in currVip.rights"
                 :key="index" @click="goUrl(item)">
              <van-icon name="success" v-if="item.hasCheckIcon" style="margin-right: 8px"/>
              {{ item.content }}
              <img class="right-item-vip-icon" v-if="item.hasVipIcon && getVipIcon(item.id)"
                   :src="getVipIcon(item.id)"
                   alt="">
              <van-icon name="arrow" v-if="item.url&&item.url.length"/>
            </div>

            <img v-if="['4','5','6'].includes(currVip.id)" :src="getVipIcon(currVip.id)" alt="" class="curr-vip-icon">
          </div>
        </div>


        <!--        支付表单-->
        <div class="pay-form"
             v-if="currPackage.isSupportYanzhi && (currPackage.vipRightId !== userVip.vipRightId || !currPackage.isSupportSameVipRightLevelUp)">
          <van-checkbox v-model="form.useYanzhiPay" shape="square" checked-color="#151d36" icon-size="14">
            <span style="font-size: 13px">用严值抵扣</span>
          </van-checkbox>
          <div class="yanzhi">
            当前严值余额
            <span class="red">{{
                yanzhiFilter(computedPrice.walletRemainYanzhi)
              }}(¥{{ moneyFilter(computedPrice.walletRemainYanzhi / 10) }})</span>
          </div>
        </div>

        <div class="pay-form"
             v-if="currPackage.isSupportInviteCode && (currPackage.vipRightId !== userVip.vipRightId || !currPackage.isSupportSameVipRightLevelUp)">
          <van-checkbox v-model="form.useInviteCode" shape="square" checked-color="#151d36" icon-size="14">
            <span style="font-size: 13px">使用邀请码</span>
          </van-checkbox>
          <input class="input" maxlength="6" v-model="form.inviteCode" v-if="form.useInviteCode"/>
        </div>
        <div class="pay-form" style="font-size: 12px;color: #999;padding-left: 58px;margin-top: 8px"
             v-if="form.useInviteCode && currPackage.isGiveYanzhiOnInviteCode===false">
          {{ currPackage.notGiveYanzhiOnInviteCodeTip }}
        </div>

        <div class="introduce" v-if="currVip.rightImgs">
          <img @click="previewRightImg(index)" v-for="(item,index) in jsonArr(currVip.rightImgs)" :src="item"
               :key="index" alt="" class="introduce-img">
        </div>

        <company/>
        <div style="height: 32px"></div>
        <user-praise-in-vip/>
        <div style="height: 32px"></div>
        <qa/>

        <div class="deal">
          <div>支付即代表您已阅读并同意</div>
          <div class="link" @click="$router.push('/about/vip')">《灵感严选会员条款》</div>
        </div>

        <div class="pay-btn">
          <template>
            <div class="discount" v-if="currVip.id === userVip.lastExpireVipRightId">
              {{ currVip.name }}·已过期{{ userVip.lastExpireVipDays }}天
            </div>
            <div class="discount" v-else>{{ currVip.priceTip }}</div>
          </template>
          <div class="confirm" @click="showPayTypeSelect">
            <div class="confirm-btn">
              <template v-if="loadings.startPay">
                正在唤起
                <van-loading style="width: 16px;height: 16px;margin-left: 4px" type="spinner" v-if="currVip.id === '4'"
                             color="#ee4a50"/>
                <van-loading style="width: 16px;height: 16px;margin-left: 4px" type="spinner"
                             v-else-if="currVip.id === '5'" color="#1a140c"/>
                <van-loading style="width: 16px;height: 16px;margin-left: 4px" type="spinner"
                             v-else-if="currVip.id === '6'" color="#e94c1b"/>
                <van-loading style="width: 16px;height: 16px;margin-left: 4px" type="spinner" v-else color="#151d36"/>
              </template>
              <template v-else-if="userVip.vipRightId === '4' && currVip.id==='6'">不支持</template>
              <template v-else-if="!userInfo || !userInfo.id">立即加入</template>
              <template v-else-if="userVip.isInfinityVip">有效期已买断</template>
              <template v-else-if="userVip.vipRightId === '3' && userVip.lastExpireVipRightId === currVip.id">立即续费
              </template>
              <template v-else-if="userVip.vipRightId === '3'">立即加入</template>
              <template v-else-if="currVip.id === userVip.vipRightId">立即续费</template>
              <template v-else-if="userVip.vipRightLevelSort > currVip.levelSort">已包含</template>
              <template v-else-if="userVip.vipRightLevelSort < currVip.levelSort">立即升级</template>
              <template v-else>已包含</template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pay-type-bottom-select ref="payTypeBottomSelect" @choose="submit"/>


    <div class="member-page-skeleton" v-if="!(currVip && currVip.id)">
      <div class="skeleton-block vip-info-card vip-info-card-skeleton"></div>
      <div style="margin: 12px 0;display: flex;align-items: center;justify-content: center">
        <div class="skeleton-block" style="height: 20px;width: 160px"></div>
        <div class="skeleton-block" style="height: 20px;width: 80px;border-radius: 100px;margin-left: 8px"></div>
      </div>
      <div class="skeleton-block vip-info-card" style="height: 40px"></div>
      <div style="display: flex;align-items: center" class="package">
        <div class="skeleton-block package-item"></div>
        <div class="skeleton-block package-item"></div>
        <div class="skeleton-block package-item"></div>
      </div>
      <div class="skeleton-block" style="height: 25px;width: 250px;margin: 0 auto;border-radius: 100px"></div>
      <div class="rights">
        <div class="rights-card skeleton-block" style="height: 200px;margin-top: 12px">
        </div>
      </div>
      <div class="skeleton-block pay-btn"></div>
    </div>

    <club-vip-encyclopedia-dialog @needClubVip="switchVipRight('5')" ref="clubVipEncyclopediaDialog"/>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import {vipRightV2Api} from "../../api/v2/vipRightV2Api";
import {walletApiV2} from "../../api/v2/walletApiV2";
import {planV2Api} from "../../api/v2/planV2Api";
import {ImagePreview} from "vant";
import PayTypeBottomSelect from "../../components/biz/payTypeBottomSelect";
import {sleep} from "../../config/util";
import Qa from "../../components/member/qa";
import Company from "../../components/member/company";
import UserPraiseInVip from "../../components/biz/userPraiseInVip.vue";
import ClubVipEncyclopediaDialog from "../../components/biz/clubVipEncyclopediaDialog.vue";

export default {
  components: {ClubVipEncyclopediaDialog, UserPraiseInVip, Company, Qa, PayTypeBottomSelect},
  computed: {
    ...mapGetters(['userInfo', 'userVip'])
  },

  data() {
    return {
      styles: {
        tabOffsetTop: 0,
        fixed: false
      },
      vipBuyRecords: [],
      allVips: [],
      remainDiscountMoney: 0,
      wallet: {
        totalRemainValue: 0
      },
      currVip: {
        id: '',
        packages: []
      },
      currPackage: {
        id: '',
        title: '',
        slogan: '',
        price: '',
        isSupportYanzhi: '',
        isSupportInviteCode: ''
      },
      sumPrice: {
        memberFree: ''
      },
      form: {
        useYanzhiPay: false,
        useInviteCode: false,
        inviteCode: '',
        payType: 1
      },
      computedPrice: {
        price: '',
        finalPriceWithoutYanzhi: '',
        finalPrice: '',
        walletRemainYanzhi: ''
      },
      loadings: {
        startPay: false
      }
    }
  },


  async created() {
    this.$store.dispatch('getMemberInfo').then()
    this.wallet = await walletApiV2.findMy()
    this.loadVipRecords().then()
    await this.loadAllVips()

    let packageIndex = this.$route.query.packageIndex
    if (packageIndex) {
      packageIndex = parseInt(packageIndex)
    }
    this.switchVipRight(this.$route.query.id || '4', packageIndex)
    this.sumPrice = await planV2Api.findMemberFreeAndNeedPayPlanSum()
  },

  mounted() {
    const rect = document.querySelector('.vip-info-scroll-card').getBoundingClientRect()
    this.styles.tabOffsetTop = rect.bottom - 10
  },


  watch: {
    'form.useYanzhiPay'() {
      this.reComputePrice()
    },
    'form.useInviteCode'() {
      this.reComputePrice()
    },
    'form.inviteCode'() {
      this.reComputePrice()
    }
  },


  methods: {

    goUrl(item){
      if(item.url && item.url.length){
        window.location.href = item.url;
      }
    },

    async loadAllVips() {
      const resProm = vipRightV2Api.findAllForPay2()
      await sleep(300)
      const res = await resProm
      const vipRights = res.vipRights
      for (const v of vipRights) {
        v.rights = JSON.parse(v.rights || '[]')
      }
      this.allVips = vipRights
      this.remainDiscountMoney = res.remainDiscountMoney
    },


    async loadVipRecords() {
      this.vipBuyRecords = await vipRightV2Api.randomGetVipBuyRecord()
    },


    // 切换当前选中的会员权益
    switchVipRight(vipRightId, packageIndex) {
      packageIndex = packageIndex || 0
      vipRightId = vipRightId || '4'
      this.currVip = this.allVips.find(v => v.id === vipRightId)
      this.switchPackage(this.currVip.packages[packageIndex])
      document.querySelector('.member-page').scrollTo({top: 0, behavior: 'smooth'})
    },


    // 切换当前选中的套餐
    switchPackage(item) {
      if (item.vipRightId === this.currVip.id) {
        this.currPackage = item
      }
      this.reComputePrice()
    },


    // 重新计算价格
    reComputePrice() {
      const currPackage = this.currPackage
      const currVip = this.currVip
      const userVip = this.userVip
      const remainDiscountMoney = this.remainDiscountMoney || 0

      let price = currPackage.price || 0
      let finalPriceWithoutYanzhi = price
      let walletRemainMoney = this.wallet.totalRemainValue / 10

      const isSupportInviteCode = currPackage.isSupportInviteCode && (currPackage.vipRightId !== userVip.vipRightId || !currPackage.isSupportSameVipRightLevelUp)
      const isSupportYanzhi = currPackage.isSupportYanzhi && (currPackage.vipRightId !== userVip.vipRightId || !currPackage.isSupportSameVipRightLevelUp)

      if (currVip.isLevelUp || (currPackage.isSupportSameVipRightLevelUp && userVip.vipRightId === currVip.id)) {
        finalPriceWithoutYanzhi -= remainDiscountMoney
        finalPriceWithoutYanzhi = Math.max(0, finalPriceWithoutYanzhi)
      }

      if (isSupportInviteCode && this.form.useInviteCode && this.form.inviteCode.length === 6) {
        finalPriceWithoutYanzhi -= this.currVip.allowanceOnInviteCode || 0
        finalPriceWithoutYanzhi = Math.max(0, finalPriceWithoutYanzhi)
      }

      let finalPrice = finalPriceWithoutYanzhi
      if (isSupportYanzhi && this.form.useYanzhiPay) {
        finalPrice -= walletRemainMoney
        finalPrice = Math.max(finalPrice, 0)

        walletRemainMoney -= finalPriceWithoutYanzhi
        walletRemainMoney = Math.max(walletRemainMoney, 0)
      }

      this.computedPrice.price = price
      this.computedPrice.finalPriceWithoutYanzhi = finalPriceWithoutYanzhi
      this.computedPrice.finalPrice = finalPrice
      this.computedPrice.walletRemainYanzhi = walletRemainMoney * 10
    },


    // 预览权益图片
    previewRightImg(index) {
      ImagePreview({
        images: this.jsonArr(this.currVip.rightImgs),
        startPosition: index
      })
    },


    // 展开选择支付方式的底部弹窗
    showPayTypeSelect() {
      if (!Object.keys(this.userInfo).length) {
        return this.$store.dispatch('showLoginDialog');
      }
      if (this.userVip.vipRightId !== '3' && this.userVip.vipRightLevelSort > this.currVip.levelSort) {
        return
      }
      if (this.userVip.isInfinityVip) {
        return this.$toast.info('您已买断VIP，不支持补差价升级')
      }

      this.$refs.payTypeBottomSelect.show()
    },


    // 提交支付
    async submit({payType, paySubType}) {
      try {
        if (!Object.keys(this.userInfo).length) {
          return this.$store.dispatch('showLoginDialog');
        }
        if (this.loadings.startPay) {
          return
        }
        this.loadings.startPay = true

        const vipRight = this.currVip
        const isSupportYanzhi = this.currPackage.isSupportYanzhi && (this.currPackage.vipRightId !== this.userVip.vipRightId || !this.currPackage.isSupportSameVipRightLevelUp)

        const fromPlanId = this.$route.query.fromPlanId
        const useYanzhiPay = isSupportYanzhi && this.form.useYanzhiPay
        // const userTicketId = this.selectedTicket ? this.selectedTicket.id : null

        const inviteCode = this.form.useInviteCode && this.form.inviteCode.length === 6 ? this.form.inviteCode : ''
        let res = await vipRightV2Api.startPayVipRight({
          vipRightId: vipRight.id,
          payType,
          paySubType,
          useYanzhiPay,
          inviteCode,
          fromPlanId,
          packageId: this.currPackage.id
        })

        if (res.isAllPayedByYanzhi) {
          this.$toast.info('支付成功');
          return window.location.href = 'https://m.001ppt.com/mine'
        }

        // 支付宝支付
        if (paySubType === 21) {
          document.write(res.payForm)
        }

        // 微信，浏览器环境
        else if (paySubType === 13) {
          res = JSON.parse(res.payForm);
          window.location.href = res.mwebUrl + '&redirect_url=https://m.001ppt.com/mine'
        }

        // 微信，内部环境
        else if (paySubType === 11) {
          res = JSON.parse(res.payForm);
          wx.chooseWXPay({
            timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
            package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: res.paySign, // 支付签名
            success: async (r) => {
              this.$loading('请求中');
              await sleep(1500);
              this.$loading.close();
              this.$toast('支付成功')
              window.location.href = 'https://m.001ppt.com/mine'
            },
            fail: (e) => {
              self.$toast.error(e.errMsg);
            },
          });
        }
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.loadings.startPay = false
      }
    }

  }


}
</script>


<style lang="less" scoped>
.member-page {
  background: #f7f7f7;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
}

.vip-info-card {
  display: flex;
  align-items: center;
  //width: 320px;
  height: 96px;
  //background: #000;
  color: #fff;
  padding: 0 14px;
  margin: 12px 32px 0 32px;
  //border-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-size: cover;
  position: relative;
  flex-shrink: 0;
}

.vip-info-scroll-card {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  top: 40px;
  padding: 12px 43px 0 43px;
  background-color: #f7f7f7;
  height: 72px;
  display: flex;
  align-items: center;
}

.vip-info-scroll-card .avatar-img {
  width: 38px;
  height: 38px;
  border-radius: 100px;
  margin-right: 15px;
}

.vip-info-scroll-card .nickName {
  font-size: 16px;
  color: #fff;
  font-weight: bolder;
}

.vip-info-scroll-card .vip-expire-info {
  font-size: 10px;
  color: #fff;
}

.vip-info-scroll-card .go-record-btn {
  color: #fff;
  position: absolute;
  right: 43px;
  top: 24px;
}

.vip-info-scroll-card::after {
  content: '';
  position: absolute;
  left: 27px;
  bottom: 0;
  right: 27px;
  height: 72px;
  background-size: cover;
  z-index: -1;
}

.go-record-btn {
  position: absolute;
  font-size: 10px;
  top: 10px;
  right: 11px;
  display: flex;
  align-items: center;
}

.member-page-1 .vip-info-card {
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-1.png");
}

.member-page-1 .vip-info-scroll-card::after {
  content: '';
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-scroll-1.png");
}

.member-page-4 .vip-info-card {
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-4.png");
}

.member-page-4 .vip-info-scroll-card::after {
  content: '';
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-scroll-4.png");
}

.member-page-5 .vip-info-card {
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-5.png");
}

.member-page-5 .vip-info-scroll-card::after {
  content: '';
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-scroll-5.png");
}

.member-page-6 .vip-info-card {
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-6.png");
}

.member-page-6 .vip-info-scroll-card::after {
  content: '';
  background-image: url("https://cdn.001ppt.cn/h5/static/image/member-page-card-scroll-6.png");
}

.vip-info-card .avatar, .vip-info-card .avatar .avatar-img {
  width: 52px;
  height: 52px;
  border-radius: 100px;
}

.vip-info-card .info {
  padding-left: 14px;
}

.vip-info-card .info .nickName {
  font-size: 20px;
  font-weight: bolder;
  height: 30px;
  line-height: 30px;
}

.vip-info-card .info .vip-expire-info {
  font-size: 12px;
}


.plan-detail-vip-popup {
  background-image: url("https://cdn.001ppt.cn/h5/static/image/detail-vip-bg.png");
  background-size: cover;
  padding-bottom: 70px;
}


.vip-buy-record {
  background: #fff;
  padding: 8px;
  width: 100vw;
  opacity: .5;
  height: 30px;
}

.vip-buy-record-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #151d36;
}

.vip-buy-record-item-vip-right-name {
  width: 68px;
  border-radius: 100px;
  color: #fff;
  background: #ee4a50;
  font-size: 9px;
  margin-left: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 38px;
}

.tab.tab-1 {
  background: url("https://cdn.001ppt.cn/h5/static/image/plan-detail-tab-trial-vip.png?ts=12412412");
  background-size: cover;
}

.tab.tab-4 {
  background: url("https://cdn.001ppt.cn/h5/static/image/plan-detail-tab-super-vip-2.png?ts=12412412");
  background-size: cover;
}

.tab.tab-5 {
  background: url("https://cdn.001ppt.cn/h5/static/image/plan-detail-tab-club-vip-2.png?ts=12412412");
  background-size: cover;
}

.tab.tab-6 {
  background: url("https://cdn.001ppt.cn/h5/static/image/plan-detail-tab-ai-vip-2.png?ts=12412412");
  background-size: cover;
}

.tab-item {
  font-size: 16px;
  opacity: .5;
  color: #fff;
  font-weight: bolder;
  transform: translateY(-4px);
  width: calc(100vw / 3);
  text-align: center;

  &.active {
    opacity: 1;
    font-size: 20px;
  }
}

.vip {
  overflow: auto;
  padding-bottom: 70px;
}

.price-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price {
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #e1494e;
  font-weight: bolder;
}

.price-tip {
  padding: 4px 16px;
  border-radius: 100px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(21, 29, 54, 0.05);
}

.slogan-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slogan {
  font-size: 14px;
  font-weight: bolder;
}

.ticket {
  padding: 2px 7px;
  border-radius: 100px;
  color: rgba(238, 74, 80, 1);
  font-size: 11px;
  background-color: rgba(248, 208, 125, 0.3);
  margin-left: 4px;
}

.validity {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151d36;
  opacity: .5;
  font-size: 12px;
  padding-top: 6px;
}

.rights {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0 16px 0;
}

.rights-card {
  padding: 12px 32px 12px 28px;
  background: #fff;
  border-radius: 6px;
  min-width: 165px;
  position: relative;
}

.rights-item {
  height: 20px;
  font-size: 12px;
  margin: 2px 0;
  font-weight: bolder;
}

.rights-item.disabled {
  opacity: .3;
}

.curr-vip-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  right: -18px;
  bottom: -5px;
  transform: rotateZ(15deg);
}

.pay-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //justify-content: center;
  height: 24px;
  padding: 0 32px;
  font-size: 13px;
}

.yanzhi {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .3;
  padding-left: 4px;
  font-size: 12px;
}

.pay-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  color: #fff;
  margin-top: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.discount {
  height: 70px;
  flex-grow: 1;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 29px;
    background-color: rgba(255, 255, 255, 0.1);
    right: 0;
  }
}

.confirm {
  height: 70px;
  width: 131px;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}


.confirm-btn {
  width: 107px;
  height: 38px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #000;
  font-family: "PingFang SC";
}


.vip-1 .rights-card {
  border: 4px solid #f8d07d;
}

.vip-1 .confirm-btn {
  background: #fff;
  color: #151D36;
}

.vip-1 .pay-btn {
  background: #f8d07d;
}

.vip-4 .rights-card {
  border: 4px solid #ee4a50;
}

.vip-4 .confirm-btn {
  background: #fff;
  color: #ee4a50;
}

.vip-4 .pay-btn {
  background: #ee4a50;
}

.vip-5 .rights-card {
  border: 4px solid #1a140c;
}


.vip-5 .confirm-btn {
  background: #ffd697;
  color: #1a140c;
}

.vip-5 .pay-btn {
  background: #1a140c;
  color: #ffd697;
}

.vip-6 .confirm-btn {
  background: #fff;
  color: #e94c1b;
}

.vip-6 .pay-btn {
  background: #e94c1b;
  color: #fff;
}

.vip-6 .rights-card {
  border: 4px solid #e94c1b;
}


.deal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  //padding-top: 38px;
  //padding-bottom: 18px;
  position: fixed;
  bottom: 70px;
  height: 14px;
  left: 0;
  right: 0;
  background: #fff;
}

.deal .link {
  color: rgba(14, 143, 251, 1);
  padding-left: 4px;
}

.introduce {
  padding-top: 12px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introduce-img {
  width: calc(100vw - 60px);
}

.choose-rate {
  font-size: 10px;
  padding: 2px 4px;
  color: #fff;
  position: absolute;
  right: 0;
  top: -15px;
  font-weight: normal;
  background-image: url("https://cdn.001ppt.cn/h5/static/image/choose-rate-bg.png");
}


.pay-form .input {
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  margin-left: 4px;
}

.package {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0;
}

.package-item {
  height: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 7px;
  background: #fff;
  max-width: 160px;
  border-radius: 5px;
  border: 4px solid transparent;
  position: relative;
}

.package-item.active {
  border: 4px solid #151D36;
}

.package-item-title {
  font-size: 12px;
  color: #151D36;
  font-weight: bolder;
  height: 19px;
  line-height: 1.5;
}

.package-item-price {
  color: #e1494e;
  font-size: 27px;
  font-weight: bolder;
  height: 37px;
  line-height: 1.5;
}

.package-item-slogan {
  color: #151D36;
  font-size: 10px;
  height: 16px;
  line-height: 1.5;
}

.package-item-validity {
  font-size: 10px;
  color: #151d36;
  opacity: .5;
}

.package-item-origin-price {
  position: absolute;
  right: -7px;
  top: -8px;
  color: #fff;
  font-size: 9px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 2px 4px;
  text-decoration: line-through;
}

.member-page-1 .package-item.active {
  border-color: #ffd697;
}

.member-page-1 .package-item-origin-price {
  background: #ffd697;
}

.member-page-4 .package-item.active {
  border-color: #e1494e;
}

.member-page-4 .package-item-origin-price {
  background: #e1494e;
}

.member-page-5 .package-item.active {
  border-color: #000;
}

.member-page-5 .package-item-origin-price {
  background: #000;
}

.member-page-6 .package-item.active {
  border-color: #e94c1b;
}

.member-page-6 .package-item-origin-price {
  background: #e94c1b;
}

.vip-plan-value {
  margin: 0 auto;
  width: 320px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 100px;
  background-color: rgba(225, 73, 78, 0.1);
  color: #e1494e;
}

.curr-package-slogan {
  font-size: 14px;
  margin: 0 8px;
}

.curr-package-discount {
  font-size: 14px;
  color: #e1494e;
}


.curr-user-not-vip .vip-info-card {
  opacity: .6;
}

.curr-user-not-vip .vip-info-scroll-card::after {
  opacity: .6;
}

.member-page-skeleton {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 40px;
  background: #fff;
}

.vip-info-card-skeleton {
  background: #fff;
}

</style>
